import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/contratos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(contrato) {
    return apiClient.post(`${contrato.id}`, contrato)
  },

  /**
   * Solicita la generación de una factura para el contrato indicado
   * @param int idContrato 
   * @returns 
   */
  generarFactura(idContrato) {
    return apiClient.post(`${idContrato}/generar-factura`)
  },

  guardar(contrato) {
    return apiClient.post('', contrato)
  },

  guardarPeriodo(idContrato, periodo){
    return apiClient.post(`${idContrato}/periodos`, periodo)
  },

  cancelar(idContrato) {
    return apiClient.post(`${idContrato}/cancelar`)
  },

  contratoJSON(idContrato) {
    return apiClient.get(`${idContrato}.json`)
  },

  contratosJSON() {
    return apiClient.get(`${API}/contratos.json`)
  },

  //listado de las cancelaciones del mes
  contratosCancelados(params) {
    params = params || {}
    return apiClient.get('/contratosCancelados', {
      params: params
    })
  },

  periodoContratoJSON(idContrato, idPeriodo) {
    return apiClient.get(`${idContrato}/periodos/${idPeriodo}.json`)
  },

  /**
   * Entrega los periodos asignados al contrato
   * @param int idContrato 
   * 
   * params puede tener el siguiente formato:
   *    {
   *      limite: int,
   *      orden: 'asc'|'desc'
   *    }
   * @param object params     
   * @returns 
   */
  periodosContratoJSON(idContrato,params) {
    params = params || {}
    return apiClient.get(`${idContrato}/periodos.json`, { params: params })
  }
}
